<template>

  <div class="p-grid">
    <div class="p-col-12 p-md-6">
      <h3><i class="pi pi-lock" style="font-size: 1.6rem"></i> Grupos de Permissões</h3>
    </div>
  </div>

  <div class="p-grid">

    <div class="p-col-12 p-md-4">
      <DataTable sortMode="single" sortField="nome" :sortOrder="1" :value="permissoes" :loading="loading">
        <Column :sortable="true" field="nome" header="Nome"></Column>
        <Column class="col-actions">
          <template #body="slotProps">
            <Button
              icon="pi pi-pencil"
              v-tooltip.left="'Alterar Grupo de Permissões'"
              class="p-button-rounded p-button-warning p-mr-2"
              @click="$router.push({ path: `/permissao/${slotProps.data.id}` })"
            />
            <Button
              icon="pi pi-trash"
              v-tooltip="'Excluir Grupo de Permissões'"
              class="p-button-rounded p-button-danger"
              v-if="slotProps.data.id != 1"
              @click="excluir($event, slotProps.data)"
            />
          </template>
        </Column>
        <template #empty>
            Nenhum Grupo de Permissões encontrado.
        </template>
      </DataTable>
    </div>

    <div class="p-col-12 p-md-8">
      <div class="card p-fluid">
        <h5 v-if="cadastro">Novo Grupo de Permissões</h5>
        <h5 v-else>Alteração de Grupo de Permissões</h5>

        <div class="p-grid">
          <div class="p-field p-md-12">
            <label for="nome">Nome</label>
            <InputText id="nome" type="text" v-model="nome" />
          </div>

          <div class="p-field p-md-12">
            <div class="p-grid">
              <div class="p-md-3" v-for="[kg,pg] of Object.entries(permissoes_opcoes)" :key="kg">
                <strong class="p-mb-2 p-d-block p-cursor-pointer" @click="selecao_permissoes(kg)">{{ pg.nome }}</strong>
                <div v-if="typeof pg.selecao_unica != 'boolean' || !pg.selecao_unica">
                  <div v-for="[k,p] of Object.entries(pg.permissoes)" :key="k" class="p-mb-1">
                    <Checkbox :value="k" v-model="permissao_permissoes" /> {{ p }}
                  </div>
                </div>
                <div v-else>
                  <div v-for="[k,p] of Object.entries(pg.permissoes)" :key="k" class="p-mb-1">
                    <RadioButton :name="kg" :value="k" v-model="permissao_permissoes_unicas[kg]" /> {{ p }}
                  </div>
                </div>
                <div v-if="pg.escopo">
                  <div class="p-mb-1 p-mt-2">
                    <RadioButton :name="'globais_' + kg" value="GLOBAL" v-model="permissao_permissoes_escopo[kg]" /> Ac. Global
                  </div>
                  <div class="p-mb-1">
                    <RadioButton :name="'globais_' + kg" value="IMOBILIARIAS" v-model="permissao_permissoes_escopo[kg]" /> Ac. Imobiliária
                  </div>
                </div>
                <div class="p-mb-1" v-if="pg.observacoes">
                  {{ pg.observacoes }}
                </div>
              </div>
            </div>
          </div>

          <div class="p-md-12" v-if="cadastro">
            <Button
              label="Gravar Novo Grupo de Permissões"
              icon="pi pi-check"
              class="p-button-success"
              @click="adicionar"
            ></Button>
          </div>

          <div class="p-md-12" v-else>
            <div class="p-grid">
              <div class="p-md-6">
                <Button
                  label="Alterar"
                  icon="pi pi-check"
                  class="p-button-success"
                  @click="alterar"
                ></Button>
              </div>
              <div class="p-md-6">
                <Button
                  label="Cancelar"
                  icon="pi pi-times"
                  class="p-button-warning"
                  @click="cancelarAlteracao"
                ></Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ConfirmPopup></ConfirmPopup>
  <Toast position="top-right" />

</template>

<script>

export default {

  data() {

    return {

      //Controle de apresentação
      cadastro: true,
      loading: true,

      // Campos do Formulário
      nome: "",
      permissao_permissoes: [],
      permissao_permissoes_escopo: {},
      permissao_permissoes_unicas: {},

      // Dados cadastrados
      permissoes: [],

      // Dado selecionado
      permissao: null,

      // Opções
      permissoes_opcoes: {
        'USUARIOS' : {
            'nome': 'Usuários',
            'escopo': true,
            'permissoes': {
                'USUARIOS_VISUALIZAR': 'Visualizar',
                'USUARIOS_CADASTRAR': 'Cadastrar',
                'USUARIOS_EXCLUIR': 'Excluir',
            },
        },
        'PESSOAS' : {
            'nome': 'Pessoas',
            'escopo': true,
            'permissoes': {
                'PESSOAS_VISUALIZAR': 'Visualizar',
                'PESSOAS_CADASTRAR': 'Cadastrar',
                'PESSOAS_EXCLUIR': 'Excluir',
            },
        },
        'IMOVEIS' : {
            'nome': 'Imóveis',
            'escopo': true,
            'permissoes': {
                'IMOVEIS_VISUALIZAR': 'Visualizar',
                'IMOVEIS_CADASTRAR': 'Cadastrar',
                'IMOVEIS_EXCLUIR': 'Excluir',
            },
        },
        'TICKETS' : {
          'nome': 'Tickets',
          'selecao_unica': true,
          'default': 'PROPRIOS',
          'observacoes': '* Direcionados a um usuário sempre estará permitido.',
          'permissoes': {
            'IMOBILIARIA': 'Todos P/ Imobiliária',
            'CORRETORA': 'Todos P/ Corretora',
            'TODOS': 'Todos',
            'PROPRIOS': 'Próprios *',
          }
        },
        'IMOBILIARIAS' : {
            'nome': 'Imobiliárias',
            'permissoes': {
                'IMOBILIARIAS_VISUALIZAR': 'Visualizar',
                'IMOBILIARIAS_CADASTRAR': 'Cadastrar',
                'IMOBILIARIAS_EXCLUIR': 'Excluir',
            },
        },
        'SEGURADORAS' : {
            'nome': 'Seguradoras',
            'permissoes': {
                'SEGURADORAS_VISUALIZAR': 'Visualizar',
                'SEGURADORAS_CADASTRAR': 'Cadastrar',
                'SEGURADORAS_EXCLUIR': 'Excluir',
            },
        },
        'SEGURO_FIANCA' : {
            'nome': 'Seguro Fiança',
            'escopo': true,
            'permissoes': {
                'SEGURO_FIANCA_VISUALIZAR': 'Visualizar',
                'SEGURO_FIANCA_CADASTRAR': 'Cadastrar',
                'SEGURO_FIANCA_INF_TECNICA': 'Inf. Técnicas',
            },
        },
        'CAPITALIZACAO' : {
            'nome': 'Capitalização',
            'escopo': true,
            'permissoes': {
                'CAPITALIZACAO_VISUALIZAR': 'Visualizar',
                'CAPITALIZACAO_CADASTRAR': 'Cadastrar',
            },
        },
        
      }

    };

  },

  mounted() {

    const self = this;

    this.$api.get('/users-permissoes').then(function (response) {

      const dados = response.data;

      if(dados.success) {

        self.permissoes = dados.data;
        self.loading = false;

      }
      
    });

    this.carregarObjetoRota();
  },

  watch: {

    $route() {
      if(this.$route.name == "permissao" || this.$route.name == "permissoes")
        this.carregarObjetoRota();
    },

    permissao: function (val) {
      
      if(val == null) {
        this.limparFormulario();
      } else {

        // Altera tela para modo de edição
        this.cadastro = false;
        
        //Popula a tela de alteração
        this.nome = val.nome;

        const permissoes = this.$utils.getStdObject(val.permissoes);

        this.permissao_permissoes = [];

        for(const [k, v] of Object.entries(this.permissoes_opcoes)) {

          if(typeof v.escopo == "boolean" && v.escopo) {
            let idx = permissoes.indexOf(k + '_GLOBAL');
            if(idx != -1) {
              this.permissao_permissoes_escopo[k] = 'GLOBAL';
            } else {
              idx = permissoes.indexOf(k + '_IMOBILIARIAS');
              if(idx != -1) {
                this.permissao_permissoes_escopo[k] = 'IMOBILIARIAS';
              }
            }
          }

          if(typeof v.selecao_unica == "boolean" && v.selecao_unica) {
            for(const kp of Object.keys(v.permissoes)) {
              const idx = permissoes.indexOf(k + '_' + kp);
              if(idx != -1) {
                this.permissao_permissoes_unicas[k] = kp;
                break;
              }
            }

          } else {

            for(const kp of Object.keys(v.permissoes)) {
              if(permissoes.indexOf(kp) != -1) {
                this.permissao_permissoes.push(kp);
              }
            }

          }

        }

      }

    },

  },

  methods: {

    // // // Métodos padrões para este modelo de tela // // //

    carregarObjetoRota() {

      const self = this;

      this.limparFormulario();

      if(typeof this.$route.params.id != "string" || this.$route.params.id == "")
        return;

      var interval = setInterval(() => {
        if(!self.loading) {
          clearInterval(interval);

          const _permissao = self.permissoes.filter((obj) => obj.id == self.$route.params.id);

          if(_permissao.length > 0) {
            self.permissao = _permissao[0];
            return;
          }

          self.$api.get('/users-permissoes/' + self.$route.params.id).then(function (response) {

            const dados = response.data;

            if(dados.success) {

              const permissao = dados.data;

              self.permissao = permissao;

            } else {
              alert(dados.message);
              self.$router.push({ path: `/permissoes` });
            }
            
          });

        }
      }, 500);

    },

    limparFormulario() {

      //Limpa as variáveis
      this.nome = "";
      this.permissao_permissoes =[];

      for(const k of Object.keys(this.permissoes_opcoes)) {
        if(typeof this.permissoes_opcoes[k].escopo == "boolean" && this.permissoes_opcoes[k].escopo)
          this.permissao_permissoes_escopo[k] = 'GLOBAL';
      }

      for(const k of Object.keys(this.permissoes_opcoes)) {
        if(typeof this.permissoes_opcoes[k].selecao_unica == "boolean" && this.permissoes_opcoes[k].selecao_unica)
          this.permissao_permissoes_unicas[k] = this.permissoes_opcoes[k].default;
      }

      // Altera tela para modo de edição
      this.cadastro = true;

    },

    // // // AÇÕES DO USUÁRIO // // //

    selecao_permissoes(kg) {

      const permissoes = Object.keys(this.permissoes_opcoes[kg].permissoes);
      let permissoes_selecionadas = 0;

      for(const k of permissoes) {
        if(this.permissao_permissoes.indexOf(k) != -1)
          permissoes_selecionadas++;
      }

      if(permissoes_selecionadas >= permissoes.length/2) {
        for(const k of permissoes) {
          const i = this.permissao_permissoes.indexOf(k);
          if(i != -1)
            this.permissao_permissoes.splice(i, 1);
        }
      } else {
        for(const k of permissoes) {
          this.permissao_permissoes.push(k);
        }
      }

    },

    cancelarAlteracao() {

      this.permissao = null;

      this.$router.push({ path: `/permissoes` });

    },

    criar_alterar(criar = true) {

      const self = this;

      /* #region Atualizar Permissões de Scopo */

      let permissao_permissoes = this.$utils.getStdObject(this.permissao_permissoes);

      let permissoes_unicas = [];
      for(const k of Object.keys(this.permissoes_opcoes)) {
        if(typeof this.permissoes_opcoes[k].selecao_unica == "boolean" && this.permissoes_opcoes[k].selecao_unica)
          permissoes_unicas.push(k); 
      }

      for(const v of permissao_permissoes) {
        if(v.indexOf('_GLOBAL') != -1 || v.indexOf('_IMOBILIARIAS') != -1) {
          this.permissao_permissoes.splice(this.permissao_permissoes.indexOf(v), 1);
        }

        for(const kpu of permissoes_unicas) {
          if(v.indexOf(kpu + '_') == 0) {
            this.permissao_permissoes.splice(this.permissao_permissoes.indexOf(v), 1);
            break;
          }
        }

      }

      for(const k in this.permissao_permissoes_unicas) {
        const v = this.permissao_permissoes_unicas[k];
        if(v) {
          this.permissao_permissoes.push(k + '_' + v);
          permissao_permissoes.push(k + '_' + v);
        }
      }

      for(const k in this.permissao_permissoes_escopo) {

        if((permissao_permissoes.filter(function (el) {
            return (new RegExp(k + "_*")).test(el);
        })).length > 0) {
          const v = this.permissao_permissoes_escopo[k];
          this.permissao_permissoes.push(k + '_' + v);
        }

      }

      /* #endregion */

      let dados = {
        'nome' : this.nome,
        'permissoes' : this.permissao_permissoes
      };

      if(!criar) {
        dados['_method'] = 'PUT';
      }

      this.$api.post('/users-permissoes' + ((!criar)?'/'+this.$route.params.id:''), dados).then(function (response) {

          if(response.data.success) {

            const v = response.data.data;

            if(criar) {

              self.permissoes.unshift(v);

            } else {

              for(const k of Object.keys(self.permissoes)) {
                if(self.permissoes[k].id == v.id) {
                  self.permissoes[k] = v;
                  break;
                }
              }

            }

            self.$toast.add({severity:'success', summary: 'Sucesso!', detail: response.data.message, life: 3000});

            self.cancelarAlteracao();

          } else {
            self.$toast.add({severity:'error', summary: 'ERRO!', detail: response.data.message, life: 5000});
          }

      }).catch(function (erro) {

        if(typeof erro.response != "undefined")
          self.$toast.add({severity:'error', summary: 'ERRO!', detail: erro.response.data.message, life: 5000});

      });

    },

    alterar() {
      this.criar_alterar(false);
    },

    adicionar() {
      this.criar_alterar();
    },

    excluir(event, obj) {

      const self = this;

      this.$confirm.require({
        target: event.currentTarget,
        message: 'Tem certeza que deseja excluir?',
        icon: 'pi pi-exclamation-triangle',
        acceptClass: 'p-button-danger',
        accept: () => {

          this.$api.post('/users-permissoes/' + obj.id, {
            '_method' : 'DELETE'
          }).then(function (response) {

            if(response.data.success) {

              for(const k of Object.keys(self.permissoes)) {
                if(self.permissoes[k].id == obj.id) {
                  self.permissoes.splice(k, 1);
                  break;
                }
              }

              self.$toast.add({severity:'success', summary: 'Sucesso!', detail: response.data.message, life: 3000});

            } else {
              self.$toast.add({severity:'error', summary: 'ERRO!', detail: response.data.message, life: 5000});
            }

          }).catch(function (erro) {

            if(typeof erro.response != "undefined")
              self.$toast.add({severity:'error', summary: 'ERRO!', detail: erro.response.data.message, life: 5000});

          });

        }
      });
      
    },

  },

};
</script>

<style scoped>
</style>